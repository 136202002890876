<template>
  <q-dialog
    v-model="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    :maximized="$q.platform.is.mobile ? maximizedToggle : false"
    class="q-pa-md"
    v-if="transaction"
  >
    <q-card :style="{ 'font-family': settings.typography }" class="q-pb-xs">
      <GoBackButton class="justify-start q-mt-md" />
      <q-item class="column">
        <q-item-section class="justify-center items-center">
          <q-icon
            right
            class="q-pa-sm q-ma-sm"
            :style="{ 'background-color': settings.color_primary }"
            :name="
              transaction.action === 'addition' ? 'arrow_forward' : 'arrow_back'
            "
            :class="
              transaction.action === 'addition' ? 'addition' : 'subtraction'
            "
            id="button-top-page-transaction"
            style="box-shadow: 0px 4px 4px 0px #00000040"
          />
        </q-item-section>

        <q-item-section class="text-center q-mb-md">
          <q-item-label>{{ transaction.type }}</q-item-label>
          <q-item-label class="fecha-past-transaction" caption
            >{{ transaction.created_at }}
          </q-item-label>
          <q-item-label
            class="price-past-transaction"
            :style="{ color: settings.color_accent }"
            caption
            ><span v-if="transaction.action === 'addition'">+</span
            ><span v-else>-</span>{{ transaction.points }}
            {{ settings.currency_abbreviation }}</q-item-label
          >
        </q-item-section>
      </q-item>

      <div v-if="transaction.external_id">
        <q-list class="q-ml-md">
          <q-item>
            <q-item-section side top>
              <q-icon name="calendar_today" />
            </q-item-section>
            <q-item-section>
              <q-item-label
                >{{ $t("BuyingProcess.transactions.transaction-number") }}:
              </q-item-label>
              <q-item-label caption lines="2">
                {{ order?.id }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-separator inset class="q-mr-xl"/>

          <q-item>
            <q-item-section side top>
              <q-icon name="local_shipping" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{
                $t("BuyingProcess.transactions.transaction-date")
              }}</q-item-label>
              <q-item-label caption lines="2">{{
                transaction.created_at
              }}</q-item-label>
            </q-item-section>
            <q-separator color="#F2F2F2" inset />
          </q-item>
          <q-separator inset class="q-mr-xl"/>

          <q-item >
            <q-item-section side top>
              <q-icon name="view_in_ar" color="" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{
                $t("BuyingProcess.transactions.transaction-state")
              }}</q-item-label>
              <q-item-label caption lines="2">
                {{
                  order?.status
                }}</q-item-label
              >
            </q-item-section>
          </q-item>
          <q-separator inset class="q-mr-xl" />
        </q-list>

        <q-item class="column">
          <q-item-section>
            <h6 class="q-ma-sm">
              {{ $t("Profile.personal-address.address") }}
            </h6>
          </q-item-section>
          <q-item-section class="q-pl-lg">
            <q-item-label>
              {{ 
              formatAddress(
                order?.shipping_address?.address,
                order?.shipping_address?.postal_code,
                order?.shipping_address?.city,
                order?.shipping_address?.province,
                order?.shipping_address?.country)
                }}
            </q-item-label>
          </q-item-section>
        </q-item>

        <q-item class="column">
          <q-item-section>
            <h6 class="q-ma-sm">
              {{ $t("BuyingProcess.transactions.transaction-information") }}
            </h6>
          </q-item-section>
          <q-item-section
            class="q-mt-md q-pl-sm"
            style="
              display: flex;
              justify-content: space-around;
              flex-direction: row;
            "
          >
            <div>
              <q-img
                v-if="transaction"
                :src="infoProduct.images.length > 0
                  ? getProductImageUrl(infoProduct.images[0])
                  : defaultImage"
              />
              <q-img v-else :src="defaultImageApp" />
            </div>
            <div style="max-width: 150px">
              <q-item-label caption
                >{{ $t("BuyingProcess.transactions.transaction-exchange") }}
              </q-item-label>
              <q-item-label>{{ infoProduct.name }}</q-item-label>
              <q-item-label
                ><span class="puntos-transaction-page"
                  >{{ settings.currency }}:
                </span>
                <span class="text-weight-bold">
                  {{
                    order?.order_lines[0]["total_value"]
                  }}

                  {{ settings.currency_abbreviation }}</span
                ></q-item-label
              >
              <q-item-label
                >{{ $t("BuyingProcess.transactions.transaction-reference") }}:
                {{ infoProduct.sku }}
              </q-item-label>
            </div>
          </q-item-section>
        </q-item>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from "vue";
import { defineAsyncComponent } from "vue";
import { mapActions, mapState } from "vuex";
import PastTransactionsShowMixin from "../mixins/PastTransactionsShowMixin";
import settings from "@/mixins/settings";
import config from "../../../../public/config.json"
import { formatAddress } from "@/services/helpers/AddressFormat";
import { getProductImageUrl } from "@/services/helpers/ProductImages.js"

const GoBackButton = defineAsyncComponent(() =>
  import("../../shared/buttons/GoBackButton.vue")
);

export default {
  mixins: [PastTransactionsShowMixin, settings],
  name: "PastTransactionsShow",
  components: {
    GoBackButton,
  },
  data() {
    return {
      infoProduct: true,
      defaultImage: this.defaultImageApp,
      order: null,
    };
  },
  props: {
    dialog: {
      default: false,
    },
    transaction: {
      external_id: {
        type: Number,
        required: true,
      },
    },
  },
  computed: {
    orders() {
      return this.$store.state.orders.orders;
    },
    products() {
      return this.$store.state.products.products;
    },

    ...mapState("auth", ["user"]),
  },
  methods: {
    getProductImageUrl,
    formatAddress,
    ...mapActions("orders", ["getOrders"]),
    ...mapActions("products", ["getProducts"]),
  },
  setup() {
    return {
      dialog: ref(false),
      maximizedToggle: true,
    };
  },

  updated() {
    this.getDataFromProduct();
    this.order = this.orders.find((x) => x.id == this.transaction?.external_id);
  },
  created() {
    this.getOrders();
    this.getProducts();
  },
};
</script>

<style lang="scss" scoped>
@import "~quasar-variables";

.q-img,
img {
  border-radius: 25px 25px 25px 0px;
  margin: 1rem;
  width: 110px;
  height: 110px;
  object-fit: contain;
}

#button-top-page-transaction {
  color: white;
  font-size: 30px;
  border-radius: 50%;
}

.fecha-past-transaction {
  letter-spacing: 3px;
}
.price-past-transaction {
  font-size: 16px;
}

@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    max-width: 560px;
    position: relative;
    left: 26%;
    top: 24px;
    min-width: 550px;
    min-height: 490px;
  }
}

.q-card {
  background-color: $gray;
}

.puntos-transaction-page {
  text-transform: capitalize;
}

.addition {
  color: v-bind("settings.color_accent");
}

.subtraction {
  color: v-bind("settings.color_primary");
}
</style>
